import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const Title1 = styled.h1`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};

  font-size: 16px;
  line-height: 24px;

  text-align: center;
  margin: 10px 0;

  ${screen.md} {
    font-size: 26px;
    line-height: 39px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 47.5px;
  }
`;

export const Title2 = styled.h2`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgb(${getToken('color-text-primary')});
  margin: 10px 0;
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};

  ${screen.md} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

export const Title3 = styled.h3`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-bold')};
  margin: 10px 0;

  &.text-left {
    text-align: left;
  }

  ${screen.md} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const Title4 = styled.h4`
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  margin: 10px 0;

  ${screen.lg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const Subtitle = styled.h2`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  text-align: center;
  margin: 10px auto;

  font-size: 16px;
  line-height: 24px;

  ${screen.lg} {
    font-size: 20px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const Paragraph1 = styled.p`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};

  margin: 10px 0;

  &.text-left {
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const Paragraph2 = styled(Paragraph1)`
  font-size: 14px;
  line-height: 21px;
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  text-align: left;

  margin: 10px 0;

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 36px;
  }
`;
