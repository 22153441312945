import Head from 'next/head';
import { NextSeo } from 'next-seo';

//screen
import { ShipperGuide } from '@/screens/ShipperGuide';

export default function ShipperGuidePage() {
  return (
    <>
      <Head>
        <meta
          name="keywords"
          content="Freight shipping, shipper services, logistics shipping, freight brokerage, shipper technology, shipping software, FTL shipping, LTL shipping, multimodal shopping, PTL, drayage, rail shipping, supply chain services, shipper software, software for shipping, online shipping software, freight management, freight procurement, freight management services, logistics freight management, "
        />
      </Head>
      <NextSeo
        nofollow={process.env.NOINDEX_FLAG}
        noindex={process.env.NOINDEX_FLAG}
        title="ShipperGuide TMS - Nex Gen Transportation Management System"
        description="Optimize your shipping operations with a freight management platform with procurement, execution and reporting."
        canonical="https://loadsmart.com/shipper/shipperguide/"
        openGraph={{
          url: 'https://loadsmart.com/shipper/shipperguide/',
        }}
      />

      <ShipperGuide />
    </>
  );
}
